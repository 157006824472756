import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NotFound from './components/not-found/NotFound';
import GuideMe from './components/guide-me/GuideMe';
import Profile from './components/users/Profile';
import ResetPassword from './components/authentication/ResetPassword';
import ApprovalTimesheet from './components/approvals/ApprovalTimesheet';
import ForgotPassword from './components/authentication/ForgotPassword';
import TaskHour from './components/taskhours/TaskHour';
import Approvals from './components/approvals/Approvals';
import Home from './components/home/Home';
import Login from './components/authentication/Login';
import Signup from './components/authentication/Signup';
import Timesheets from './components/timesheets/Timesheets';
import Users from './components/users/Users';
import Tasks from './components/tasks/Tasks';
import Projects from './components/projects/Projects';
import Clients from './components/clients/Clients';

// ProtectedRoute component to manage access based on roles
const ProtectedRoute = ({ element, allowedRoles, role }) => {
    if (allowedRoles.includes(role)) {
        return element;
    }
    return <Navigate to="/not-found" />;
};

const AppRoutes = () => {
    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData) {
            setRole(userData.role);
        }
        setLoading(false);
    }, []);

    // Show a loading message while determining user role
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                {/* Auth URLs */}
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Signup />} />
                <Route path='/forgotpassword' element={<ForgotPassword />} />
                <Route path='/reset-password/:code' element={<ResetPassword />} />

                {/* Home URL */}
                <Route path='/' element={<Home />} />

                {/* Fallback for undefined routes */}
                <Route path="*" element={<NotFound />} />

                {/* Role-specific routes for Admin */}
                <Route path='/clients' element={<ProtectedRoute element={<Clients />} allowedRoles={['Admin']} role={role} />} />
                <Route path='/projects' element={<ProtectedRoute element={<Projects />} allowedRoles={['Admin']} role={role} />} />
                <Route path='/tasks' element={<ProtectedRoute element={<Tasks />} allowedRoles={['Admin']} role={role} />} />
                <Route path='/users' element={<ProtectedRoute element={<Users />} allowedRoles={['Admin']} role={role} />} />
                <Route path='/guide-me' element={<ProtectedRoute element={<GuideMe />} allowedRoles={['Admin']} role={role} />} />

                {/* Common URLs */}
                <Route path='/timesheets' element={<Timesheets />} />
                <Route path='/timesheet/:timesheetId' element={<TaskHour />} />
                <Route path='/approvals' element={<Approvals />} />
                <Route path='/approval/:timesheetId' element={<ApprovalTimesheet />} />
                <Route path='/profile' element={<Profile />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
